import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import axios from "axios";

import Footer from "../components/constants/Footer";
import Navbar from "../components/constants/Navbar";
import InstructionsModal from "../components/modals/InstructionsModal";
import ProgressBar from "../components/constants/ProgressBar";

import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { Label, Radio, RadioGroup } from "@headlessui/react";
import { motion, AnimatePresence } from "framer-motion";

const getSectionColor = (section) => {
  const colors = {
    Faithful: "#95CBE4",
    Pure: "#DCE5EF",
    Loving: "#EF837B",
    Hopeful: "#C8EA6C",
    Patient: "#38b2ac",
    Meek: "#C1B1A8",
    True: "#EBD224",
    Wise: "#FFE55D",
    Grateful: "#F3A616",
    Joyous: "#FFE55D",
    Merciful: "#2F9393",
    Peaceful: "#95CBE4",
  };

  return colors[section] || "#ffffff";
};

const SelfAssessment = () => {
  // State variables
  const user = useSelector((state) => state.user.user);
  const language = useSelector((state) => state.user.language);

  const [nextQuestion, setNextQuestion] = useState(null);
  const [nextQuestionNum, setNextQuestionNum] = useState(0);
  const [nextQuestionId, setNextQuestionId] = useState(null);
  const [questionSet, setQuestionSet] = useState([]);
  const [loading, setLoading] = useState(true);
  const [response, setResponse] = useState("");

  const [showStartModal, setShowStartModal] = useState(true);
  const [showEndModal, setShowEndModal] = useState(false);

  const attemptRef = useRef(null);
  const navigate = useNavigate();

  // Define the options array once
  const optionsArray = ["Never", "Rarely", "Sometimes", "Often", "Always"];

  // Database functions
  const createOrFindAttempt = async () => {
    console.log("Creating or finding attempt for user", user);

    try {
      // Try to find existing attempt
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/attempt/${user.uid}`
      );

      if (response.status === 200 && response.data) {
        console.log("Attempt found:", response.data);
        attemptRef.current = response.data;
        return response.data;
      } else {
        console.log("No unfinished attempt found. Creating new attempt.");
        const newResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/attempt/${user.uid}`
        );
        console.log("New attempt:", newResponse.data);
        attemptRef.current = newResponse.data;
        return newResponse.data;
      }
    } catch (error) {
      if (error.response && error.response.status === 404) {
        // No unfinished attempt found, create a new one
        const newResponse = await axios.post(
          `${process.env.REACT_APP_API_URL}/api/attempt/${user.uid}`
        );
        attemptRef.current = newResponse.data;
        return newResponse.data;
      } else {
        console.error("Error creating or finding attempt:", error);
        return null;
      }
    }
  };

  const saveUserAnswer = async (questionId, score) => {
    console.log("Saving user answer for question", questionId);
    try {
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/answers/save/${user.uid}/${attemptRef.current._id}/${questionId}`,
        { score }
      );
      console.log("User answer saved successfully", score);
    } catch (error) {
      console.error("Error saving response:", error);
    }
  };

  // User action handlers
  const handlePrev = () => {
    if (nextQuestionNum > 0) {
      const newQuestionNum = nextQuestionNum - 1;
      setNextQuestionNum(newQuestionNum);
      setNextQuestion(questionSet[newQuestionNum]);
      setNextQuestionId(questionSet[newQuestionNum]._id);
      setResponse(""); // Optionally reset response if needed
    }
  };

  const handleNext = async () => {
    if (!response) {
      alert("Please answer the question before proceeding.");
      return;
    }

    // Capture the response value before any asynchronous operations
    const currentResponse = response;
    const currentQuestionId = nextQuestionId;

    try {
      const numericResponse = optionsArray.indexOf(currentResponse) + 1;
      // Save response to the current question in attempt doc
      await axios.post(
        `${process.env.REACT_APP_API_URL}/api/attempt/${attemptRef.current._id}/save-response`,
        { questionId: currentQuestionId, response: numericResponse }
      );
      console.log("Response saved to attempt doc:", numericResponse);
      await saveUserAnswer(currentQuestionId, numericResponse);
    } catch (error) {
      console.error("Error saving response:", error);
    }

    if (nextQuestionNum < questionSet.length - 1) {
      console.log(
        "Assessment continues, Next question number:",
        nextQuestionNum + 1
      );
      const newQuestionNum = nextQuestionNum + 1;
      setNextQuestionNum(newQuestionNum);
      setNextQuestion(questionSet[newQuestionNum]);
      setNextQuestionId(questionSet[newQuestionNum]._id);
    } else {
      console.log("Assessment finished. Calling finishAttempt");
      try {
        await axios.put(
          `${process.env.REACT_APP_API_URL}/api/attempt/${attemptRef.current._id}/finish`
        );
        setShowEndModal(true);
      } catch (error) {
        console.error("Error finishing attempt:", error);
      }
    }

    setResponse(""); // Reset response after handling
  };

  const setUpAssessment = async () => {
    try {
      const attempt = await createOrFindAttempt();
      if (!attempt) {
        throw new Error("Attempt creation or retrieval failed");
      }

      console.log("Setting up assessment with attempt:", attempt);
      const answered = attempt.qSet.answered || 0;
      setNextQuestionNum(answered);

      // Fetch all questions and filter the ones in the attempt
      const fetchedQuestions = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/questions`
      );
      const filteredQuestions = fetchedQuestions.data.filter((q) =>
        attempt.qSet.qs.includes(q._id)
      );

      setQuestionSet(filteredQuestions);
      setNextQuestion(filteredQuestions[answered]);
      setNextQuestionId(filteredQuestions[answered]._id);
    } catch (error) {
      console.error("Error setting up assessment:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (user?.uid) {
      setUpAssessment();
    }
    setShowStartModal(true);
  }, [user]);

  useEffect(() => {
    if (questionSet.length > 0) {
      setNextQuestion(questionSet[nextQuestionNum]);
    }
  }, [language, questionSet, nextQuestionNum]);

  const getLocalizedText = (question) => {
    console.log("Getting localized text for question", question);
    if (!question) return "";
    return language === "pt-br" ? question.ptbrText : question.qText;
  };

  const isQuestionAvailable = nextQuestion !== null;
  const currentSection = isQuestionAvailable ? nextQuestion.mod : "";
  const progressPercentage = questionSet.length
    ? Math.floor(((nextQuestionNum + 1) / questionSet.length) * 100)
    : 0;

  if (loading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <p className="text-2xl text-gray-800">Loading...</p>
      </div>
    );
  }

  return (
    <div>
      <Navbar />

      {/* MODAL: Show at start of assessment */}
      {showStartModal && (
        <InstructionsModal
          stage="start"
          onClose={() => setShowStartModal(false)}
        />
      )}

      {/* MODAL: Show at end of assessment */}
      {showEndModal && (
        <InstructionsModal stage="end" onClose={() => navigate("/results")} />
      )}

      {/* Main Container */}
      <div className="min-h-screen px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
        {/* Colored Background */}
        <div
          style={{ backgroundColor: getSectionColor(currentSection) }}
          className="max-w-screen-lg p-4 mx-auto my-6 rounded-lg shadow-lg h-fit sm:p-6"
        >
          <h2 className="mb-4 text-3xl font-bold text-center text-gray-900 md:text-4xl">
            Be ye {currentSection}
          </h2>

          {/* Progress Bar */}
          <div className="mt-6">
            <ProgressBar progress={progressPercentage} />
          </div>

          {/* Question Card */}
          <div className="overflow-hidden rounded-lg shadow">
            {/* Current question card */}
            <AnimatePresence mode="wait">
              {isQuestionAvailable && (
                <motion.div
                  key={nextQuestionId}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{ opacity: 1, y: 0 }}
                  exit={{ opacity: 0, y: -50 }}
                  transition={{ duration: 0.3 }}
                  className="w-full h-full p-6 px-4 py-5 bg-white border rounded-lg shadow-lg sm:p-6"
                >
                  <h2 className="mb-4 text-xl font-bold text-center text-gray-800">
                    {getLocalizedText(nextQuestion)}
                  </h2>

                  {/* Reference */}
                  {nextQuestion.reference?.[0]?.rText && (
                    <div className="mb-4 text-sm italic text-center text-gray-500">
                      <p>{nextQuestion.reference[0].rText}</p>
                      <div className="mt-2 font-bold">
                        {nextQuestion.reference[0].scripture.book}{" "}
                        {nextQuestion.reference[0].scripture.chapter}:
                        {nextQuestion.reference[0].scripture.verse}
                      </div>
                    </div>
                  )}

                  {/* Options */}
                  <div className="mt-6">
                    <RadioGroup value={response} onChange={setResponse}>
                      <Label className="sr-only">Rate your response</Label>
                      <div className="grid grid-cols-1 gap-3 sm:grid-cols-5 md:grid-cols-5">
                        {optionsArray.map((option) => (
                          <Radio
                            key={option}
                            value={option}
                            className={({ checked }) =>
                              `${
                                checked
                                  ? "bg-secondary-600 text-white"
                                  : "bg-white text-gray-900"
                              } flex items-center justify-center rounded-md px-3 py-2 text-sm sm:text-base font-medium border border-gray-300 cursor-pointer focus:outline-none w-full`
                            }
                          >
                            {option}
                          </Radio>
                        ))}
                      </div>
                    </RadioGroup>
                  </div>

                  {/* Navigation Buttons */}
                  <div className="flex items-center justify-between mt-6">
                    <button
                      onClick={handlePrev}
                      disabled={nextQuestionNum === 0}
                      className={`text-gray-600 hover:text-gray-800 ${
                        nextQuestionNum === 0
                          ? "cursor-not-allowed opacity-50"
                          : ""
                      }`}
                    >
                      <FaArrowLeft className="w-6 h-6" />
                    </button>
                    <span className="text-lg font-semibold text-gray-800">
                      {nextQuestionNum + 1} of {questionSet.length}
                    </span>
                    {nextQuestionNum < questionSet.length - 1 ? (
                      <button
                        onClick={handleNext}
                        className="px-4 py-2 text-white rounded-lg bg-secondary-600 hover:bg-secondary-700 disabled:opacity-50 disabled:cursor-not-allowed"
                        disabled={!response}
                      >
                        <FaArrowRight className="w-6 h-6" />
                      </button>
                    ) : (
                      <button
                        onClick={handleNext}
                        className="px-4 py-2 text-white bg-green-600 rounded-lg hover:bg-green-700 disabled:opacity-50 disabled:cursor-not-allowed"
                        disabled={!response}
                      >
                        Submit
                      </button>
                    )}
                  </div>
                </motion.div>
              )}
            </AnimatePresence>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SelfAssessment;
