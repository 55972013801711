import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import axios from "axios";
import Navbar from "../constants/Navbar";

// Utility function to combine Tailwind classes conditionally
function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const ScriptureViewer = () => {
  const navigate = useNavigate();

  // URL params from React Router
  const { volume, book, chapter } = useParams();

  // Hard-coded volumes data
  const volumesData = [
    { volume_id: 1, volume_lds_url: "ot", displayName: "Old Testament" },
    { volume_id: 2, volume_lds_url: "nt", displayName: "New Testament" },
    { volume_id: 3, volume_lds_url: "bofm", displayName: "Book of Mormon" },
    {
      volume_id: 4,
      volume_lds_url: "dc-testament",
      displayName: "Doctrine & Covenants",
    },
    {
      volume_id: 5,
      volume_lds_url: "pgp",
      displayName: "Pearl of Great Price",
    },
  ];

  // For the books, chapters, and verses
  const [booksData, setBooksData] = useState([]);
  const [chaptersData, setChaptersData] = useState([]);
  const [versesData, setVersesData] = useState([]);

  // Local state
  const [selectedVolume, setSelectedVolume] = useState(volume || "");
  const [selectedBook, setSelectedBook] = useState(book || "");
  const [selectedChapter, setSelectedChapter] = useState(chapter || "");
  console.log(selectedVolume, selectedBook, selectedChapter);

  // Sidebar open/close for mobile
  const [sidebarOpen, setSidebarOpen] = useState(false);

  // Accordion toggles
  const [volumesOpen, setVolumesOpen] = useState(true);
  const [booksOpen, setBooksOpen] = useState(false);
  const [chaptersOpen, setChaptersOpen] = useState(false);

  // Find the current volume object (if any) based on URL param
  const currentVolumeObj = volumesData.find(
    (volObj) => volObj.volume_lds_url === volume
  );

  // ----------------------------------------------------------------------------
  // Data fetching
  // ----------------------------------------------------------------------------

  const fetchBooks = async (volumeId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/scriptures/volume/${volumeId}`
      );
      setBooksData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchChapters = async (bookId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/scriptures/book/${bookId}/chapters`
      );
      setChaptersData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchVerses = async (chapterId) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/api/scriptures/chapter/${chapterId}`
      );
      setVersesData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  // Whenever volume changes, fetch books
  useEffect(() => {
    if (currentVolumeObj) {
      fetchBooks(currentVolumeObj.volume_id);
    } else {
      setBooksData([]);
    }
  }, [volume]);

  // Whenever book changes, fetch chapters
  useEffect(() => {
    if (book) {
      fetchChapters(book);
    } else {
      setChaptersData([]);
    }
  }, [book]);

  // Whenever chapter changes, fetch verses
  useEffect(() => {
    if (chapter) {
      fetchVerses(chapter);
    } else {
      setVersesData([]);
    }
  }, [chapter]);

  // ----------------------------------------------------------------------------
  // Accordion auto-toggle logic
  // ----------------------------------------------------------------------------
  useEffect(() => {
    if (!volume) {
      setVolumesOpen(true);
      setBooksOpen(false);
      setChaptersOpen(false);
    } else if (volume && !book) {
      setVolumesOpen(false);
      setBooksOpen(true);
      setChaptersOpen(false);
    } else if (volume && book && !chapter) {
      setVolumesOpen(false);
      setBooksOpen(false);
      setChaptersOpen(true);
    } else if (volume && book && chapter) {
      setVolumesOpen(false);
      setBooksOpen(false);
      setChaptersOpen(true);
    }
  }, [volume, book, chapter]);

  // ----------------------------------------------------------------------------
  // Handlers for navigation
  // ----------------------------------------------------------------------------

  const handleVolumeSelect = (volumeSlug) => {
    setSelectedVolume(volumeSlug);
    setSelectedBook("");
    setSelectedChapter("");
    navigate(`/reader/${volumeSlug}`);
    // Close sidebar on mobile
    setSidebarOpen(false);
  };

  const handleBookSelect = (bookId) => {
    setSelectedBook(bookId);
    setSelectedChapter("");
    if (volume && bookId) {
      navigate(`/reader/${volume}/${bookId}`);
    }
    // Close sidebar on mobile
    setSidebarOpen(false);
  };

  const handleChapterSelect = (chapterId) => {
    setSelectedChapter(chapterId);
    if (volume && book && chapterId) {
      navigate(`/reader/${volume}/${book}/${chapterId}`);
    }
    // Close sidebar on mobile
    setSidebarOpen(false);
  };

  // ----------------------------------------------------------------------------
  // Derived data
  // ----------------------------------------------------------------------------
  const selectedBookObj = booksData.find((b) => b.book_id.toString() === book);
  const selectedChapterObj = chaptersData.find(
    (c) => c.chapter_id.toString() === chapter
  );

  // ----------------------------------------------------------------------------
  // Build the sidebar lists
  // ----------------------------------------------------------------------------
  const sideNavVolumes = volumesData.map((v) => ({
    name: v.displayName,
    slug: v.volume_lds_url,
    current: volume === v.volume_lds_url,
  }));

  const sideNavBooks = (volume && booksData.length > 0 ? booksData : []).map(
    (b) => ({
      name: b.book_title,
      bookId: b.book_id,
      current: b.book_id.toString() === book,
    })
  );

  const sideNavChapters = (
    book && chaptersData.length > 0 ? chaptersData : []
  ).map((c) => ({
    name: `Chapter ${c.chapter_number}`,
    chapterId: c.chapter_id,
    current: c.chapter_id.toString() === chapter,
  }));

  // ----------------------------------------------------------------------------
  // Sidebar content
  // ----------------------------------------------------------------------------
  const sidebarContent = (
    <div className="flex flex-col h-full border-r border-gray-200 bg-gray-50">
      <div className="p-4 text-lg font-bold text-gray-800 border-b border-gray-200">
        Scripture Nav
      </div>
      <nav aria-label="Sidebar" className="flex-1 p-4 overflow-y-auto">
        {/* Volumes */}
        <div>
          <button
            onClick={() => setVolumesOpen((prev) => !prev)}
            className="flex items-center justify-between w-full mb-2 text-sm font-semibold tracking-wide text-left text-gray-500 uppercase hover:text-indigo-600"
          >
            Volumes
            <span>{volumesOpen ? "−" : "+"}</span>
          </button>
          {volumesOpen && (
            <ul role="list" className="mb-4 space-y-1">
              {sideNavVolumes.map((item) => (
                <li key={item.slug}>
                  <button
                    onClick={() => handleVolumeSelect(item.slug)}
                    className={classNames(
                      item.current
                        ? "bg-gray-100 text-indigo-600"
                        : "text-gray-700 hover:bg-gray-100 hover:text-indigo-600",
                      "group w-full text-left flex items-center gap-x-2 rounded-md p-2 text-sm font-semibold"
                    )}
                  >
                    {item.name}
                  </button>
                </li>
              ))}
            </ul>
          )}
        </div>

        {/* Books */}
        {volume && sideNavBooks.length > 0 && (
          <div>
            <button
              onClick={() => setBooksOpen((prev) => !prev)}
              className="flex items-center justify-between w-full mb-2 text-sm font-semibold tracking-wide text-left text-gray-500 uppercase hover:text-indigo-600"
            >
              Books
              <span>{booksOpen ? "−" : "+"}</span>
            </button>
            {booksOpen && (
              <ul role="list" className="mb-4 space-y-1">
                {sideNavBooks.map((item) => (
                  <li key={item.bookId}>
                    <button
                      onClick={() => handleBookSelect(item.bookId)}
                      className={classNames(
                        item.current
                          ? "bg-gray-100 text-indigo-600"
                          : "text-gray-700 hover:bg-gray-100 hover:text-indigo-600",
                        "group w-full text-left flex items-center gap-x-2 rounded-md p-2 text-sm font-semibold"
                      )}
                    >
                      {item.name}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}

        {/* Chapters */}
        {book && sideNavChapters.length > 0 && (
          <div>
            <button
              onClick={() => setChaptersOpen((prev) => !prev)}
              className="flex items-center justify-between w-full mb-2 text-sm font-semibold tracking-wide text-left text-gray-500 uppercase hover:text-indigo-600"
            >
              Chapters
              <span>{chaptersOpen ? "−" : "+"}</span>
            </button>
            {chaptersOpen && (
              <ul role="list" className="space-y-1">
                {sideNavChapters.map((item) => (
                  <li key={item.chapterId}>
                    <button
                      onClick={() => handleChapterSelect(item.chapterId)}
                      className={classNames(
                        item.current
                          ? "bg-gray-100 text-indigo-600"
                          : "text-gray-700 hover:bg-gray-100 hover:text-indigo-600",
                        "group w-full text-left flex items-center gap-x-2 rounded-md p-2 text-sm font-semibold"
                      )}
                    >
                      {item.name}
                    </button>
                  </li>
                ))}
              </ul>
            )}
          </div>
        )}
      </nav>
    </div>
  );

  // ----------------------------------------------------------------------------
  // Layout
  // ----------------------------------------------------------------------------
  return (
    <div className="flex flex-col h-screen">
      {/* Top navbar (with hamburger for mobile) */}
      <Navbar />

      {/* Hamburger button on small screens */}
      <div className="absolute top-4 left-4 md:hidden">
        <button
          type="button"
          onClick={() => setSidebarOpen(true)}
          className="inline-flex items-center justify-center p-2 text-gray-500 bg-white rounded-md shadow hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
        >
          <span className="sr-only">Open sidebar</span>
          <svg
            className="w-6 h-6"
            stroke="currentColor"
            fill="none"
            viewBox="0 0 24 24"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M4 6h16M4 12h16M4 18h16"
            />
          </svg>
        </button>
      </div>

      {/* MOBILE SIDEBAR OVERLAY */}
      {sidebarOpen && (
        <div className="fixed inset-0 z-40 flex md:hidden">
          {/* Overlay */}
          <div
            className="fixed inset-0 bg-gray-600 bg-opacity-75"
            onClick={() => setSidebarOpen(false)}
            aria-hidden="true"
          />
          {/* Sidebar itself */}
          <div className="relative flex flex-col w-64 h-full bg-white">
            <div className="absolute top-0 right-0 p-2">
              <button
                type="button"
                onClick={() => setSidebarOpen(false)}
                className="inline-flex items-center justify-center p-2 text-gray-500 bg-white rounded-md shadow hover:text-gray-700 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              >
                <span className="sr-only">Close sidebar</span>
                <svg
                  className="w-6 h-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>
            {sidebarContent}
          </div>
        </div>
      )}

      {/* FLEX CONTAINER: SIDEBAR (DESKTOP) + MAIN CONTENT */}
      <div className="flex flex-1 overflow-hidden">
        {/* DESKTOP SIDEBAR */}
        <div className="hidden md:flex md:w-64 md:flex-col md:shrink-0">
          {sidebarContent}
        </div>

        {/* MAIN CONTENT */}
        <div className="flex-1 p-6 overflow-y-auto">
          {/* If no volume is selected */}
          {!volume && (
            <div className="text-gray-600">
              <h1 className="mb-2 text-xl font-bold">Choose a volume</h1>
              <p>Select a volume from the sidebar to begin reading.</p>
            </div>
          )}

          {/* Volume selected, but no book */}
          {volume && !book && (
            <div>
              <h1 className="mb-2 text-2xl font-bold text-gray-800">
                {currentVolumeObj?.displayName}
              </h1>
              <p className="text-gray-600">
                Please choose a book from the sidebar.
              </p>
            </div>
          )}

          {/* Book selected, but no chapter */}
          {volume && book && !chapter && selectedBookObj && (
            <div>
              <h1 className="mb-2 text-2xl font-bold text-gray-800">
                {currentVolumeObj?.displayName}
              </h1>
              <h2 className="mb-4 text-xl font-semibold text-gray-700">
                {selectedBookObj.book_title}
              </h2>
              <p className="text-gray-600">
                Please choose a chapter from the sidebar.
              </p>
            </div>
          )}

          {/* All three selected => display verses */}
          {volume && book && chapter && selectedChapterObj && (
            <div className="mx-auto max-w-prose">
              <h1 className="mb-2 text-2xl font-bold text-gray-800">
                {currentVolumeObj?.displayName}
              </h1>
              <h2 className="mb-4 text-xl font-semibold text-gray-700">
                {selectedBookObj?.book_title} – Chapter{" "}
                {selectedChapterObj?.chapter_number}
              </h2>
              <div className="flex flex-col gap-3">
                {versesData.map((verse) => (
                  <div key={verse._id}>
                    <span className="mr-2 font-semibold text-gray-800">
                      {verse.verse_number}
                    </span>
                    <span className="text-gray-700">
                      {verse.scripture_text}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ScriptureViewer;
