import { useSelector } from "react-redux";
import { selectUser } from "../store/reducers/userSlice";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children, isAdminOnly = false }) => {
  const user = useSelector(selectUser);

  if (!user) {
    console.log("User is not logged in", user);
    return <Navigate to="/auth" />;
  }

  if (isAdminOnly && !user.isAdmin) {
    console.log("User is not an admin", user.isAdmin);
    return <Navigate to="/" />; // Redirect non-admins to the main page
  }

  return children;
};

export default PrivateRoute;
