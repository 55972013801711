import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchUserProfileData } from "../../store/actions/userActions";
import axios from "axios";

import Navbar from "../../components/constants/Navbar";

// TODO: Add methods to delete, edit, and add questions
// TODO: Implement a form for admins to add new questions
// TODO: Include an inline edit feature for question text or scripture references
// TODO: Add confirmation modals for delete operations

const AdminDashboard = () => {
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);

  // Fetch User Profile Data
  useEffect(() => {
    if (user?.uid) {
      dispatch(fetchUserProfileData(user.uid));
    }
  }, [user, dispatch]);

  const [questions, setQuestions] = useState([]);
  const [showQuestions, setShowQuestions] = useState(false);

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchQuestions = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/questions/`
        );
        setQuestions(response.data);
      } catch (err) {
        setError("Failed to load questions.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchQuestions();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <div className="container p-6 mx-auto">
      <Navbar />
      <h1 className="mb-6 text-3xl font-bold text-center">Admin Dashboard</h1>
      <button
        className="px-4 py-2 font-bold text-white bg-blue-500 rounded hover:bg-blue-700"
        onClick={() => setShowQuestions(!showQuestions)}
      >
        Show Questions
      </button>

      {loading && <div className="mt-6">Loading...</div>}
      {error && <div className="mt-6 text-red-500">Error: {error}</div>}

      {showQuestions && (
        <div className="mt-6">
          <ul>
            {questions.map((q) => (
              <li key={q._id} className="p-4 mb-4 border rounded shadow">
                <p className="font-bold">Mark of Discipleship: {q.mod}</p>
                <p>Polarity: {q.polarity}</p>
                <p>Type: {q.type}</p>
                <p>Question: {q.qText}</p>
                <p>Portuguese Translation: {q.ptbrText}</p>
                <div>
                  <p>References:</p>
                  {q.reference && q.reference.length > 0 ? (
                    q.reference.map((ref, index) => (
                      <div key={index} className="ml-4">
                        <p>- {ref.rText}</p>
                        {ref.scripture && (
                          <p>
                            Scripture: {ref.scripture.book}{" "}
                            {ref.scripture.chapter}:{ref.scripture.verse}
                          </p>
                        )}
                      </div>
                    ))
                  ) : (
                    <p>No references available.</p>
                  )}
                </div>
                <p>Total Responses: {q.total_responses}</p>
                <p>Average Score: {q.avg_score}</p>
                <p>Median Score: {q.median_score}</p>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default AdminDashboard;
